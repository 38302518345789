import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "../tables/GeneralTable"
import FormModal from "../links/FormModal"
import QrCodeModal from "../links/QrCodeModal"
import SuperFilter from "../layout/SuperFilter"
import { formatInTimeZone } from 'date-fns-tz'
import PageSignifiers from "../layout/PageSignifiers"
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOpenLink:null,
      linkFormOpen: false,
      superFilters:[]
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSaveLinkCallback = this.onSaveLinkCallback.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);
    this.tableRef = React.createRef();
  }

  mapFilteredContacts(links) {
    return links;
  }

  getColumns() {
    const { user } = this.props;
    const disabledColumns = this.props.disabledColumns || []
    let allColumns = [
      { title: 'Original URL', field: 'url', render: (row)=>(<a href={row.url} className="sg-link-table-original-url-link sg-link-table-link">{row.url}</a>)},
      { title: 'Shortened URL', field: 'slug', render: (row)=>(<a href={`/l/${row.slug}`} className="sg-link-table-link">growl.link/l/{row.slug}</a>)},
      { defaultSort: "desc", title: 'Created At', field: 'created_at', render: (row)=>(formatInTimeZone(new Date(row.created_at),'America/Los_Angeles', 'yyyy-MM-dd p'))},
      {sorting: false, title: 'Actions', field: 'actions', render: (row) => (
        <div className='dashboard-entry-icons-container'>
          <a href={`/admin/links/${row.slug}`}><i className="dashboard-entry-icon fa-solid fa-eye"></i></a>
          <QrCodeModal link={row}>
            <a href={`#`}><i className="dashboard-entry-icon fa-solid fa-qrcode"></i></a>
          </QrCodeModal>
          <a href={`#`} onClick={() => {this.openModal(row)}}><i className="dashboard-entry-icon fa-solid fa-pen-to-square"></i></a>
        </div>)
      },
      { title: 'Created By', field: 'created_by', render: (row)=>(row.user.first_name + " " + row.user.last_name)},
    ]
    return allColumns;
  }

  renderAllLinks() {
    const { superFilters } = this.state;
    const tag_superFilter=superFilters.find(x=>x.filterID =="tag")
    const user_superFilter=superFilters.find(x=>x.filterID =="user")
    let tag_superFilterValue = "all"
    const superFilterUrlAdditions = []
    if(tag_superFilter) {
      tag_superFilterValue = tag_superFilter.filterValue
      superFilterUrlAdditions.push(`tag=${tag_superFilterValue}`)
    }
    if(user_superFilter) {
      superFilterUrlAdditions.push(`user=${user_superFilter.filterValue}`)
    }

    const url = `/admin/api/links?${superFilterUrlAdditions.join('&')}`
    return (
      <GeneralTable
        columns={this.getColumns()}
        dataUrl={url}
        dataUrlMapCallback={this.mapFilteredContacts}
        tableRef={this.tableRef}
      />
    )
  }

  renderFormModal() {
    const {linkFormOpen, selectedOpenLink} = this.state;
    return (
      <FormModal
        onSave={this.onSaveLinkCallback}
        closeModal={this.closeModal}
        open={linkFormOpen}
        link={selectedOpenLink}
      />
    )
  }

  onSaveLinkCallback() {
    this.closeModal();
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  openModal(link) {
    this.setState({
      linkFormOpen: true,
      selectedOpenLink: link,
    })
  }

  closeModal() {
    this.setState({
      linkFormOpen: false,
      selectedOpenLink: null,
    })
  }


  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    })
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  renderSuperFilter() {
    const { tags, users } = this.props;
    return (
      <SuperFilter
        onChange={this.setSuperFilters}
        filters={[
          {
            id: "tag",
            type:"select",
            options: [{label: 'All', id: 'all', filterFunction: (x,i,t)=>{return true}}].concat(tags.map(x=>{return {
              label: x.name,
              id: x.name,
            }})),
            outerClassName: "col-xs-12 col-md-6 col-lg-3",
            placeholder: "Filter By Tag"
          },

          {
            id: "user",
            type:"select",
            options: [{label: 'All', id: '', filterFunction: (x,i,t)=>{return true}}].concat(users.map(x=>{return {
              label: `${x.first_name} ${x.last_name}`,
              id: x.id,
            }})),
            outerClassName: "col-xs-12 col-md-6 col-lg-3",
            placeholder: "Filter by Created By"
          },
        ]}
      />
    )
  }

  render() {
    return (
      <div className="container">
        <PageSignifiers />
        {this.renderFormModal()}
        <div className="row">
          <div className="col-xs-12">
            <div className="sg-content-card">
              <h1> Links
                <button className="pull-right btn sg-new-link-button font-orangutan"onClick={this.openModal}>
                  New Link
                </button>
              </h1>
              <br/>
              <br/>
              {this.renderSuperFilter()}
              <br/>
              <br/>
              {this.renderAllLinks()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;
