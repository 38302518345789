import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select'
import MultiSelect from 'react-select'
class SuperFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValues: props.defaultValues ? props.defaultValues : {}
    }
    this.getSelectedFilters = this.getSelectedFilters.bind(this);
    this.setFilterValue = this.setFilterValue.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.setMultiSelectFilterValue = this.setMultiSelectFilterValue.bind(this);
  }

  componentDidMount() {
    const { onChange } = this.props;
    if(onChange) {
      onChange(this.getSelectedFilters());
    }
  }

  /******
  * Filter Schema (object)
  * id: Unique ID
  * type: onoff, select,
  * Options (array, object): {id, label, filterFunction, filterType(optional)}
  * outerClassName(optional)
  * innerClassName(optional)
  * placeholder(optional)
  *****/
  renderFilters() {
    const { filters } = this.props;
    return filters.map((f,index)=>
      <div
        key={`super-filter-${index}-${f.id}`}
        className={
          `${f.outerClassName} ${this.isFilterSelected(f.id) ? "cnt-super-filter-active" : "cnt-super-filter-inactive"}`
        }
      >
        {this.renderFilter(f,index)}
      </div>
    )
  }

  getSelectedFilters() {
    const {filters} = this.props;
    const {filterValues} = this.state;
    const selectedFilterKeys = Object.keys(filterValues).filter(x=>filterValues[x]);
    return filters.filter(x=> selectedFilterKeys.indexOf(x.id) != -1).map(f1=> {
      const option = f1.options.find(f2=>f2.id == filterValues[f1.id])
      return {
        filterType: option ? option.filterType : null,
        filterID: f1.id,
        filterOptionID: option ? option.id : null,
        filterFunction: option ? option.filterFunction : null,
        filterValue: filterValues[f1.id]
      }
    })
  }

  isFilterSelected(id) {
    const {filterValues} = this.state;
    const selectedFilterKeys = Object.keys(filterValues).filter(x=>filterValues[x]);
    return selectedFilterKeys.findIndex(x=> x == id) != -1;
  }

  setMultiSelectFilterValue(filterObject,value) {
    const  { defaultValues } = this.props;
    if((value||[]).length > 0){
      const recentlyAddedValue=value[value.length - 1]
      const recentlyAddedOption = filterObject.options.find(x=>x.id == recentlyAddedValue.value);
      if(recentlyAddedOption['solo']) {
        value = [recentlyAddedValue]
      }else {
        value = value.filter(x=> {
          const xobject = filterObject.options.find(y=>y.id == x.value);
          return !xobject.solo
        })
      }
    }else {
      value = [{value: null}]
    }

    this.setFilterValue(filterObject.id, (value||[]).map(x=>x.value));
  }

  setFilterValue(name,value) {
    let { filterValues } = this.state;
    const { onChange } = this.props;
    if(value == null) {
      delete filterValues[name];
    }else {
      filterValues[name] = value;
    }
    if(onChange) {
      onChange(this.getSelectedFilters());
    }
    this.setState({
      filterValues
    })

  }

  renderFilter(filterObject,index) {
    const {filterValues} = this.state;
    const value = filterValues[filterObject.id];
    switch(filterObject.type) {
      case "select":
        return (
          <Select
            clearable={false}
            options={
              filterObject.options.map(x=> {
                return {
                  value: x.id,
                  label: x.label
                }
              })
            }
            classNamePrefix='cnt-default-layout-super-filter-select'
            placeholder={filterObject.placeholder}
            onChange={(e)=>this.setFilterValue(filterObject.id, e.value)}
            value={
              this.getSelectFilterValue(filterObject, value)
            }
          />
      );
      case "multiselect":
      return (
          <MultiSelect
            options={
              filterObject.options.map(x=> {
                return {
                  value: x.id,
                  label: x.label
                }
              })
            }
            onChange={(value) =>
              this.setMultiSelectFilterValue(filterObject, value)
            }
            value ={(value||[]).map(x=>this.getSelectFilterValue(filterObject,x))}
            closeMenuOnSelect={false}
            isMulti
            classNamePrefix='cnt-default-layout-super-filter-select'
          />
      );
      default:
        return (<div className="cnt-error"> Must Select a type for a filter.</div>)
    }
  }

  getSelectFilterValue(filter,value) {
    if(value) {
      const foundValue = filter.options.find(x=>x.id == value)
      if(foundValue) {
        return {
          label: foundValue.label,
          value: foundValue.id
        }
      }
    }
    return null
  }

  renderResetFilters() {
    const {filterValues} = this.state;
    const count = Object.keys(filterValues||{}).filter(x=> filterValues[x])
    return (
      <>
      <img
        data-for={`sg-cnt-default-super-filter-reset-button`}
        data-tip
        className="cnt-superfilter-reset-button"
        src={count.length > 0 ? "/layout/superfilter/reset-filters.svg" : "/layout/superfilter/reset-filters-gray.svg"}
        onClick={this.resetFilters}
      />
      </>
    )
  }

  resetFilters() {
    const { onChange } = this.props;

    if(onChange) {
      onChange([]);
    }
    this.setState({
      filterValues: {}
    })
  }

  render() {
    const { value } = this.props;
    return (
      <div className="cnt-default-layout-super-filter row">
        {this.renderFilters()}
        {this.renderResetFilters()}
      </div>
    );
  }
}

export default SuperFilter;
