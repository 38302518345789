import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import LinkForm from "./LinkForm"
class FormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const {closeModal, open, outerClassNames, link } = this.props;
    return (
      <Modal
        className="sg-modal white-background disable-scrollbars"
        overlayClassName="sg-modal-overlay font-proxima"
        isOpen={open}
        onRequestClose={closeModal}
        contentLabel=""
      >
        <div className="sg-agenda-modal-close-button" onClick={closeModal}> &#10005; </div>
        <LinkForm {...this.props}/>
      </Modal>
    );
  }
}

export default FormModal;
