import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "../tables/GeneralTable"
import FormModal from "./FormModal"
import SuperFilter from "../layout/SuperFilter"
import { formatInTimeZone } from 'date-fns-tz'
import PageSignifiers from "../layout/PageSignifiers"
class CalendarDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOpenCalendar:null,
      linkFormOpen: false,
      superFilters:[]
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSaveLinkCallback = this.onSaveLinkCallback.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);
    this.tableRef = React.createRef();
  }

  getColumns() {
    const { user } = this.props;
    const disabledColumns = this.props.disabledColumns || []
    let allColumns = [
      { defaultSort: "title", title: 'Title', field: 'title'},
      { title: 'ICS URL', field: 'slug', render: (row)=>(<a href={`/c/i/${row.slug}`} className="sg-link-table-link">growl.link/c/i/{row.slug}</a>)},
      { title: 'GCal URL', field: 'slug', render: (row)=>(<a href={`/c/g/${row.slug}`} className="sg-link-table-link">growl.link/c/g/{row.slug}</a>)},
      { defaultSort: "desc", title: 'Created At', field: 'created_at', render: (row)=>(formatInTimeZone(new Date(row.created_at),'America/Los_Angeles', 'yyyy-MM-dd p'))},
      {sorting: false, title: 'Actions', field: 'actions', render: (row) => (
        <div className='dashboard-entry-icons-container'>
          <a href={`#`} onClick={() => {this.openModal(row)}}><i className="dashboard-entry-icon fa-solid fa-pen-to-square"></i></a>
          <a href={`#`} onClick={() => {this.clone(row)}}><i className="dashboard-entry-icon fa-solid fa-copy"></i></a>
        </div>)
      },
      { title: 'Created By', field: 'created_by', render: (row)=>(row.user.first_name + " " + row.user.last_name)},
    ]
    return allColumns;
  }

  clone(calendar) {
    const newCalendar = JSON.parse(JSON.stringify(calendar));
    newCalendar.id = null;
    newCalendar.slug = calendar.slug + "-clone";
    newCalendar.title = calendar.title + " - Clone";
    this.openModal(newCalendar)
  }

  renderAllCalendars() {
    const { superFilters } = this.state;
    const tag_superFilter=superFilters.find(x=>x.filterID =="tag")
    let tag_superFilterValue = "all"
    if(tag_superFilter) {
      tag_superFilterValue = tag_superFilter.filterValue
    }
    return (
      <GeneralTable
        columns={this.getColumns()}
        dataUrl={tag_superFilterValue == 'all' ? '/admin/api/calendars' : `/admin/api/calendars?tag=${tag_superFilterValue}`}
        dataUrlMapCallback={(e)=>{return e}}
        tableRef={this.tableRef}
      />
    )
  }

  renderFormModal() {
    const {linkFormOpen, selectedOpenCalendar} = this.state;
    return (
      <FormModal
        onSave={this.onSaveLinkCallback}
        closeModal={this.closeModal}
        open={linkFormOpen}
        calendar={selectedOpenCalendar}
      />
    )
  }

  onSaveLinkCallback() {
    this.closeModal();
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  openModal(link) {
    this.setState({
      linkFormOpen: true,
      selectedOpenCalendar: link,
    })
  }

  closeModal() {
    this.setState({
      linkFormOpen: false,
      selectedOpenCalendar: null,
    })
  }


  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    })
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  renderSuperFilter() {
    const { tags } = this.props;
    return (
      <SuperFilter
        onChange={this.setSuperFilters}
        filters={[
          {
            id: "tag",
            type:"select",
            options: [{label: 'All', id: 'all', filterFunction: (x,i,t)=>{return true}}].concat(tags.map(x=>{return {
              label: x.name,
              id: x.name,
            }})),
            outerClassName: "col-xs-12 col-md-6 col-lg-3",
            placeholder: "Tags"
          },
        ]}
      />
    )
  }

  render() {
    return (
      <div className="container">
        {this.renderFormModal()}
        <PageSignifiers />
        <div className="row">
          <div className="col-xs-12">
            <div className="sg-content-card">
              <h1> Calendars
                <button className="pull-right btn sg-new-link-button font-orangutan"onClick={this.openModal}>
                  New Calendar
                </button>
              </h1>
              <br/>
              <br/>
              {this.renderSuperFilter()}
              <br/>
              <br/>
              {this.renderAllCalendars()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CalendarDashboard;
