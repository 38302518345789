import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

import Cookies from "universal-cookie";
class LinkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  isEditing() {
    const { link } = this.props;
    return !!link.id;
  }

  renderField(name,formikProps, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const required = properties['required'] || false
    const customValidation = properties['customValidation'] || false;
    const erroring = required && checkError && (!formikProps.values.link[name] || formikProps.values.link[name].length <= 0 || customValidation);

    return (
        <Field
          onChange={(e) => {
              const { link } = formikProps.values;
              link[name] = properties.formatValue ? properties.formatValue(e.target.value) : e.target.value
              formikProps.setFieldValue('link', link);
              this.setState({
                dirty: true,
              });
            }
          }
          disabled={properties['disabled']}
          component={properties['component']}
          className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`link[${name}]`}
        />
      )
  }
  
  formatSlug(value) {
    return (value||"").replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, '-')
  }


  renderForm(formikProps) {
    const { error } = this.state;
    const { user } = this.props;
    return (
      <Form className="sg-link-form" >
        <div className="row">
          <div className="col-xs-12">
            <label>
              URL to shorten
            </label>
            {this.renderField('url', formikProps,{required: true})}
          </div>
          <div className="col-xs-12">
            <label>
              Custom URL (optional)
            </label>
            <div class="input-prepend">
              <div class="input-group">
                <div class="input-group-addon">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Growl.link/</span>
                </div>
                {this.renderField('slug', formikProps, {
                  formatValue: this.formatSlug
                })}
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <label>
              UTM Parameters (Optional)
            </label>
            <br/>
            UTMs can help you track web traffic in analytics tools.
          </div>
          <div className="col-xs-12">
            <label>
              Source
            </label>
            {this.renderField('utm_source', formikProps)}
          </div>
          <div className="col-xs-12">
            <label>
              Medium
            </label>
            {this.renderField('utm_medium', formikProps)}
          </div>
          <div className="col-xs-12">
            <label>
              Campaign
            </label>
            {this.renderField('utm_campaign', formikProps)}
          </div>
          <div className="col-xs-12">
            <label>
              Tags (Seperate by comma)
            </label>
            {this.renderField('tag_list', formikProps)}
          </div>
          <div className="col-xs-12 sg-form-error">
            {error}
          </div>
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }

  renderButtons(formikProps) {
    const { user } = this.props;
    return (
      <div className="col-xs-12" style={{paddingTop: "20px"}}>
        <button onClick={formikProps.handleSubmit} type="button" class="sg-submit-button btn sg-button font-orangutan"> Save </button>
      </div>
    );
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { link } = this.props;
    return this.isEditing() ? `/admin/links/${link.id}` : `/admin/links`;
  }


  render() {
    const { user, link, onSave } = this.props;
    return (
      <Formik
        initialValues={{
          link: {
            url: link ? link.url : "",
            slug: link ? link.slug : "",
            utm_source:  link ? link.utm_source : "",
            utm_medium:  link ? link.utm_medium : "",
            utm_campaign:  link ? link.utm_campaign : "",
            tag_list: link ? (link.tag_list||[]).join(", ") : "",
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json',
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(onSave) {
                  onSave();
                }
              }
              else {
                this.setState({
                  error: json.error,
                  erroring: true
                })
              }
            });
        }}
        render={(formikProps) => (
          <div className="sg-link-form">
          {this.renderForm(formikProps)}
          </div>
        )}
      />
    );
  }
}

export default LinkForm;
