import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import QRCode from "react-qr-code";
import Checkbox from "../layout/Checkbox"
class QrCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      qrStyle: "default",
    };
    this.closeModal = this.closeModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  closeModal() {
    this.setState({
      open: false
    })
  }

  toggleModal() {
    const { open } = this.state;
    this.setState({
      open: !open,
      qrStyle: "default"
    })
  }

  renderQRCode() {
    const { link } = this.props;
    const { qrStyle } = this.state;
    switch(qrStyle) {
      case "heart":
        return (
          <QRCode
            value={`https://growl.link/l/${link.slug}`}
            bgColor="#FFFFFF"
            fgColor="#cb242a"
          />
        )
      case "black-heart":
        return (
          <QRCode
            value={`https://growl.link/l/${link.slug}`}
            bgColor="#FFFFFF"
            fgColor="#cb242a"
          />
        )
      default:
        return (
          <QRCode
            value={`https://growl.link/l/${link.slug}`}
          />
        )
    }
  }
  render() {
    const { outerClassNames, link, children } = this.props;
    const { open, qrStyle } = this.state;
    return (
      <>
        <div style={{display:"inline"}} className={`${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="sg-modal white-background disable-scrollbars"
          overlayClassName="sg-modal-overlay font-proxima"
          isOpen={open}
          onRequestClose={this.closeModal}
          contentLabel=""
        >
          <div className="text-center">
            <div id={`link-qr-code-${link.slug}`} className={`growl-link-qr-modal-qr growl-link-qr-modal-qr-${qrStyle}`}>
              <div className={`growl-link-qr-modal-qr-outer-container`}>
                {this.renderQRCode()}
              </div>
            </div>
          </div>
          <div className="growl-link-qr-modal-styles">
            <div>
              <br/>
              <br/>
              <br/>
              <b> Styles: </b>
              <Checkbox
                value={qrStyle=="default"}
                onClick={(e)=>this.setState({qrStyle: "default"})}
              /> <label> Default </label>
              <Checkbox
                value={qrStyle=="heart"}
                onClick={(e)=>this.setState({qrStyle: "heart"})}
              /> <label> Heart </label>
              <Checkbox
                value={qrStyle=="black-heart"}
                onClick={(e)=>this.setState({qrStyle: "black-heart"})}
              /> <label> Heart (B&W) </label>
            </div>
          </div>
          <div className="growl-link-qr-modal-buttons">
          </div>
        </Modal>
      </>
    );
  }
}

export default QrCodeModal;
