
import React from "react";

const PageSignifiers = () => {
  return <div className="sg-content-card">
    <div className="sg-page-signifiers">
      <a href="/">Links</a>
      <a className="sg-page-signifiers-link" href="/admin/calendars">Calendars</a>
    </div>
  </div>;
}

export default PageSignifiers