import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "../tables/GeneralTable"
import FormModal from "../links/FormModal"
import   { formatInTimeZone } from 'date-fns-tz';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.tableRef = React.createRef();
  }

  mapFilteredContacts(links) {
    return links;
  }

  getColumns() {
    const { user } = this.props;
    const disabledColumns = this.props.disabledColumns || []
    let allColumns = [
      { defaultSort:'desc', title: 'Time', field: 'created_at', render: (row)=> (
        formatInTimeZone(new Date(row.created_at),'America/Los_Angeles', 'yyyy-MM-dd p')
      )},
      { title: 'IP', field: 'ip', render: (row)=>(
        <a href={`https://whatismyipaddress.com/ip/${this.fromInt(row.ip.addr)}`} className="sg-link-table-link">
          {row.ip ? this.fromInt(row.ip.addr) : ""}
        </a>
      )},
      { title: 'Unique ID (if applicable)', field: 'unique_id'},

    ]
    return allColumns;
  }

  fromInt(ipl){
    return ( (ipl>>>24) +'.' +
        (ipl>>16 & 255) +'.' +
        (ipl>>8 & 255) +'.' +
        (ipl & 255) );
  };

  renderHistory() {
    const { link } = this.props;
    return (
      <GeneralTable
        columns={this.getColumns()}
        dataUrl={`/admin/api/links_history?id=${link.id}`}
        dataUrlMapCallback={this.mapFilteredContacts}
        tableRef={this.tableRef}
      />
    )
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="sg-content-card">
              <a href="/admin/">
                Back to dashboard
              </a>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="sg-content-card">
              <h1> View Link Metrics
              </h1>
              <br/>
              <br/>
              {this.renderHistory()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;
